body {
    margin: 0;
    overflow: hidden;
}

.container {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.shape {
    position: absolute;
    top: -20px;
    font-size: 20px;
    animation: fall 3s linear infinite;
}

@keyframes fall {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    90% {
        transform: translateY(100vh);
        opacity: 1;
    }
    100% {
        transform: translateY(100vh);
        opacity: 0;
    }
}
